import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  WebhookCreateBody,
  WebhookUpdateBody,
} from '~/types/body/webhookCreateBody'
import type {
  WebhookEvent,
  WebhookEventResponse,
  WebhookEventStatus,
  WebhookSetting,
} from '~/types/webhook'

/**
 * Webhook API
 */
export class KickflowWebhookApi extends KickflowApi {
  async getWebhookSettings(
    pagingQuery: PagingQuery
  ): Promise<PagingList<WebhookSetting>> {
    const params = pagingQuery.toParams()
    return await this.getPagingList('/webhookSettings', params)
  }

  async getWebhookSetting(id: string): Promise<WebhookSetting> {
    return await this.get(`/webhookSettings/${id}`)
  }

  async getWebhookEvents(
    webhook: WebhookSetting,
    paging: PagingQuery,
    status: WebhookEventStatus[]
  ): Promise<PagingList<WebhookEvent>> {
    const queryParams = paging.toParams()
    queryParams.status = status
    return await this.getPagingList(
      `/webhookSettings/${webhook.id}/events`,
      queryParams
    )
  }

  async getWebhookEventResponses(
    webhookSettingId: string,
    webhookEventId: string,
    paging: PagingQuery
  ): Promise<PagingList<WebhookEventResponse>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/webhookSettings/${webhookSettingId}/events/${webhookEventId}/responses`,
      queryParams
    )
  }

  async createWebhookSetting(
    params: WebhookCreateBody
  ): Promise<WebhookSetting> {
    return await this.post('/webhookSettings', params)
  }

  async updateWebhookSetting(
    setting: WebhookSetting,
    params: WebhookUpdateBody
  ): Promise<WebhookSetting> {
    return await this.patch(`/webhookSettings/${setting.id}`, params)
  }

  async deleteWebhookSetting(setting: WebhookSetting): Promise<WebhookSetting> {
    return await this.delete(`/webhookSettings/${setting.id}`)
  }

  async retryWebhookEvent(webhook: WebhookSetting, event: WebhookEvent) {
    return await this.post(
      `/webhookSettings/${webhook.id}/events/${event.id}/retry`
    )
  }
}
