import { default as indexj29ZHUSvAuMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as index634rF9ZOMgMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as editnVohz8kdYHMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexwDouZbIyjJMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newyuyumy1o7yMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexCvYNkSPKhWMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexo4YqLFiMZGMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as indexwH4R2rGH3pMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callbackEzHi2z7VDoMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as indexme26HVSwgKMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirect0yT3zlmuekMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as indexhgE8X4C51sMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as index8sUMmcv9ynMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indexeAdRd51STCMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as indexDKxljfyrBlMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as resultsdpv8ueKMWuMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as indexKr3ka3BOGFMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callbackOF8BgouHiAMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as indexDSPCwgvVUPMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as indexq0RkSRqOyyMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callbackEOqorWxGyWMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexhM4n58VojzMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectLtVbUj9hddMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91eventId_93P2mj71qmETMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue?macro=true";
import { default as index4d1zXtIuN0Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue?macro=true";
import { default as index0utnhXR8deMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_93aw3Pbs0K4YMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as indexKT5bV0ijn8Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexhSpZBLOhLTMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indexQjwmoBTyUiMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as indexSPOsv7iFbKMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as indexhi4Em4edQZMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as indexgo65sDGXZ0Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as indexp2YNB8blCBMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as indexfvv6yrDpPJMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as workflowsdzMOqCdYs5Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexXowefyyRfMMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as index4qeBOsSbPMMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csv4GF8Eiw9QIMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as indexOV8eEIAewHMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_934IB629KPeLMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historyf7ThRrSScKMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as index8jLedbXU4EMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_93IlVsn9CRNDMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as indexRbSwVv0aV9Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as newNWU1DkD3rWMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as indextNGUXvW4JhMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexYi143LDlLoMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_9342AwaMHovTMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexdzN8aLEQk1Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as editOdl8Lk2hEXMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as indexGWEVZ5xPLDMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as indexudVdXD3hUpMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93pyiayh02ixMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexwfq2uC590KMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexoueozUtB1NMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as new5fIVXDbbbmMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexyXkgr7XPrWMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as indextgZXJC7wJlMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistantsANFwk8GomZMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backupsmTv4Nn9Im2Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as indexGjf21rxKa2Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsmtOnf8nh4SMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notifications5OITW680WOMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remindfTDi61jWqZMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settingsUNz4bB64xCMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomainmhnUjori5rMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as working7W87qQubLTMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as workflowsmydVPTgCdhMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue?macro=true";
import { default as indexTmginBgz3HMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue?macro=true";
import { default as cleanvlIihZrEpiMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticket2ySguaTsn4Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taskHnJhc9zOATMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as viewerpBY89N8O6MMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/viewer.vue?macro=true";
import { default as _91id_93jrEcrzPW8UMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csv0fcQ84eSZfMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as indexbRP5uGlmxhMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as invitebGzXF4SWE5Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliated1v895sAz8KMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as editpCCIgk0Zu6Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as index8VxlO1wJHyMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93XIZ4tVZiSQMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as index4CVtdABNEQMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as indexxWGwX9MvrDMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newHwcVQAWLwaMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as admin5hBpZhpJ29Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signinhGvqOmwzoUMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callbackL2hSy2HoolMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback2HQNi9i0bInMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback2oxzuLmHRsTMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_emailchNUmdvAwVMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as indexCanA3DfHaiMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as indexcLRtwfc9RMMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newjxd4A9MEHpMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexOoFZt79XPlMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexKph2VFCPmtMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as indexDPoznMdBHDMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsigna2Wk9XxENGMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatTCo3G7avpoMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationsgCVAojxOwsMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokensN9HfDOUZHLMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as indexgyjFoWvbcwMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editrDrAsTYWZ1Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApproverm2SNg4EN54Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as indexyNygKt0NxXMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printkd3EUNP5IDMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as index4YViJJfEQwMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexGCUXgIFfSaMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as editawuRBXY3CiMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexk15YDQeE7QMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as newcz4vUb4e65Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchQxQYKpWSCGMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboardWMMDWmLkQVMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredb2n57bZmSpMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexTjfpBomIsBMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexxM0itHygo9Meta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_93XNousKO1ZKMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misocaZyVJkwf6cHMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectfvEsOrcQMKMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as selectz6PfKORajIMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signinQyD1jGeIaZMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSsoGrYU8SbOTkMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcomeCoyKhf1gvOMeta } from "/tmp/build_eed8ea9d/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: admin5hBpZhpJ29Meta?.name,
    path: "/en/admin",
    meta: admin5hBpZhpJ29Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: indexj29ZHUSvAuMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: index634rF9ZOMgMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: editnVohz8kdYHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexwDouZbIyjJMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newyuyumy1o7yMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexCvYNkSPKhWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexo4YqLFiMZGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: indexwH4R2rGH3pMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callbackEzHi2z7VDoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: indexme26HVSwgKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirect0yT3zlmuekMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: indexhgE8X4C51sMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: index8sUMmcv9ynMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indexeAdRd51STCMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: indexDKxljfyrBlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: resultsdpv8ueKMWuMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: indexKr3ka3BOGFMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callbackOF8BgouHiAMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: indexDSPCwgvVUPMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: indexq0RkSRqOyyMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callbackEOqorWxGyWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexhM4n58VojzMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectLtVbUj9hddMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___en",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93P2mj71qmETMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: index4d1zXtIuN0Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: index0utnhXR8deMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_93aw3Pbs0K4YMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: indexKT5bV0ijn8Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexhSpZBLOhLTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___en",
    path: "",
    meta: indexQjwmoBTyUiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: indexSPOsv7iFbKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: indexhi4Em4edQZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: indexgo65sDGXZ0Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: indexp2YNB8blCBMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: indexfvv6yrDpPJMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflowsdzMOqCdYs5Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexXowefyyRfMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: index4qeBOsSbPMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csv4GF8Eiw9QIMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: indexOV8eEIAewHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_934IB629KPeLMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historyf7ThRrSScKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: index8jLedbXU4EMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_93IlVsn9CRNDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: indexRbSwVv0aV9Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: newNWU1DkD3rWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: indextNGUXvW4JhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexYi143LDlLoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_9342AwaMHovTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexdzN8aLEQk1Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: editOdl8Lk2hEXMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: indexGWEVZ5xPLDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: indexudVdXD3hUpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93pyiayh02ixMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: indexwfq2uC590KMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexoueozUtB1NMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: new5fIVXDbbbmMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexyXkgr7XPrWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: indextgZXJC7wJlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistantsANFwk8GomZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backupsmTv4Nn9Im2Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: indexGjf21rxKa2Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsmtOnf8nh4SMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notifications5OITW680WOMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remindfTDi61jWqZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settingsUNz4bB64xCMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomainmhnUjori5rMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: working7W87qQubLTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___en",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflowsmydVPTgCdhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue")
  },
  {
    name: "admin-ticketNumberKeys___en",
    path: "ticketNumberKeys",
    meta: indexTmginBgz3HMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue")
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: cleanvlIihZrEpiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticket2ySguaTsn4Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taskHnJhc9zOATMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: viewerpBY89N8O6MMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/viewer.vue")
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93jrEcrzPW8UMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csv0fcQ84eSZfMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: indexbRP5uGlmxhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: invitebGzXF4SWE5Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliated1v895sAz8KMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: editpCCIgk0Zu6Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: index8VxlO1wJHyMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93XIZ4tVZiSQMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: index4CVtdABNEQMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: indexxWGwX9MvrDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newHwcVQAWLwaMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: admin5hBpZhpJ29Meta?.name,
    path: "/admin",
    meta: admin5hBpZhpJ29Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: indexj29ZHUSvAuMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: index634rF9ZOMgMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: editnVohz8kdYHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexwDouZbIyjJMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newyuyumy1o7yMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexCvYNkSPKhWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexo4YqLFiMZGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: indexwH4R2rGH3pMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callbackEzHi2z7VDoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: indexme26HVSwgKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirect0yT3zlmuekMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: indexhgE8X4C51sMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: index8sUMmcv9ynMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indexeAdRd51STCMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: indexDKxljfyrBlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: resultsdpv8ueKMWuMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: indexKr3ka3BOGFMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callbackOF8BgouHiAMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: indexDSPCwgvVUPMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: indexq0RkSRqOyyMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callbackEOqorWxGyWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexhM4n58VojzMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectLtVbUj9hddMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___ja",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93P2mj71qmETMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: index4d1zXtIuN0Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: index0utnhXR8deMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_93aw3Pbs0K4YMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: indexKT5bV0ijn8Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexhSpZBLOhLTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___ja",
    path: "",
    meta: indexQjwmoBTyUiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: indexSPOsv7iFbKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: indexhi4Em4edQZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: indexgo65sDGXZ0Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: indexp2YNB8blCBMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: indexfvv6yrDpPJMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflowsdzMOqCdYs5Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexXowefyyRfMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: index4qeBOsSbPMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csv4GF8Eiw9QIMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: indexOV8eEIAewHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_934IB629KPeLMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historyf7ThRrSScKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: index8jLedbXU4EMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_93IlVsn9CRNDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: indexRbSwVv0aV9Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: newNWU1DkD3rWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: indextNGUXvW4JhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexYi143LDlLoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_9342AwaMHovTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexdzN8aLEQk1Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: editOdl8Lk2hEXMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: indexGWEVZ5xPLDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: indexudVdXD3hUpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93pyiayh02ixMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: indexwfq2uC590KMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexoueozUtB1NMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: new5fIVXDbbbmMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexyXkgr7XPrWMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: indextgZXJC7wJlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistantsANFwk8GomZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backupsmTv4Nn9Im2Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: indexGjf21rxKa2Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsmtOnf8nh4SMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notifications5OITW680WOMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remindfTDi61jWqZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settingsUNz4bB64xCMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomainmhnUjori5rMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: working7W87qQubLTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___ja",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflowsmydVPTgCdhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue")
  },
  {
    name: "admin-ticketNumberKeys___ja",
    path: "ticketNumberKeys",
    meta: indexTmginBgz3HMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue")
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: cleanvlIihZrEpiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticket2ySguaTsn4Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taskHnJhc9zOATMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: viewerpBY89N8O6MMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/tickets/viewer.vue")
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93jrEcrzPW8UMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csv0fcQ84eSZfMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: indexbRP5uGlmxhMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: invitebGzXF4SWE5Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliated1v895sAz8KMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: editpCCIgk0Zu6Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: index8VxlO1wJHyMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93XIZ4tVZiSQMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: index4CVtdABNEQMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: indexxWGwX9MvrDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newHwcVQAWLwaMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signinhGvqOmwzoUMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signinhGvqOmwzoUMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callbackL2hSy2HoolMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callbackL2hSy2HoolMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback2HQNi9i0bInMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback2HQNi9i0bInMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback2oxzuLmHRsTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback2oxzuLmHRsTMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_emailchNUmdvAwVMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_emailchNUmdvAwVMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: dashboardWMMDWmLkQVMeta?.name,
    path: "/en/dashboard",
    meta: dashboardWMMDWmLkQVMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: indexCanA3DfHaiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: indexcLRtwfc9RMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newjxd4A9MEHpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexOoFZt79XPlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexKph2VFCPmtMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: indexDPoznMdBHDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsigna2Wk9XxENGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatTCo3G7avpoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationsgCVAojxOwsMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokensN9HfDOUZHLMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: indexgyjFoWvbcwMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editrDrAsTYWZ1Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverm2SNg4EN54Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: indexyNygKt0NxXMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printkd3EUNP5IDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: index4YViJJfEQwMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexGCUXgIFfSaMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: editawuRBXY3CiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexk15YDQeE7QMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: newcz4vUb4e65Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchQxQYKpWSCGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: dashboardWMMDWmLkQVMeta?.name,
    path: "/dashboard",
    meta: dashboardWMMDWmLkQVMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: indexCanA3DfHaiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: indexcLRtwfc9RMMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newjxd4A9MEHpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexOoFZt79XPlMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexKph2VFCPmtMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: indexDPoznMdBHDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsigna2Wk9XxENGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatTCo3G7avpoMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationsgCVAojxOwsMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokensN9HfDOUZHLMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: indexgyjFoWvbcwMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editrDrAsTYWZ1Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverm2SNg4EN54Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: indexyNygKt0NxXMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printkd3EUNP5IDMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: index4YViJJfEQwMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexGCUXgIFfSaMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: editawuRBXY3CiMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexk15YDQeE7QMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: newcz4vUb4e65Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchQxQYKpWSCGMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredb2n57bZmSpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredb2n57bZmSpMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexTjfpBomIsBMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexTjfpBomIsBMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexxM0itHygo9Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexxM0itHygo9Meta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_93XNousKO1ZKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_93XNousKO1ZKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misocaZyVJkwf6cHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misocaZyVJkwf6cHMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectfvEsOrcQMKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectfvEsOrcQMKMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: selectz6PfKORajIMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "select___ja",
    path: "/select",
    meta: selectz6PfKORajIMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signinQyD1jGeIaZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signinQyD1jGeIaZMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSsoGrYU8SbOTkMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSsoGrYU8SbOTkMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcomeCoyKhf1gvOMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/welcome.vue")
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcomeCoyKhf1gvOMeta || {},
    component: () => import("/tmp/build_eed8ea9d/frontend_nuxt3/pages/welcome.vue")
  }
]