import { KickflowAiApi } from '~/api/kickflowAiApi'
import { KickflowAssistantApi } from '~/api/kickflowAssistantApi'
import { KickflowAuditLogApi } from '~/api/kickflowAuditLogApi'
import { KickflowAuthApi } from '~/api/kickflowAuthApi'
import { KickflowAutomationApi } from '~/api/kickflowAutomationApi'
import { KickflowBillingApi } from '~/api/kickflowBillingApi'
import { KickflowBoxApi } from '~/api/kickflowBoxApi'
import { KickflowCalculationVariableApi } from '~/api/kickflowCalculationVariableApi'
import { KickflowChatworkApi } from '~/api/kickflowChatworkApi'
import { KickflowClimberCloudApi } from '~/api/kickflowClimberCloudApi'
import { KickflowCloudSignApi } from '~/api/kickflowCloudSignApi'
import { KickflowCommentApi } from '~/api/kickflowCommentApi'
import { KickflowExternalApi } from '~/api/kickflowExternalApi'
import { KickflowFcmTokenApi } from '~/api/kickflowFcmTokenApi'
import { KickflowFileApi } from '~/api/kickflowFileApi'
import { KickflowFolderApi } from '~/api/kickflowFolderApi'
import { KickflowGoogleChatApi } from '~/api/kickflowGoogleChatApi'
import { KickflowGoogleDriveApi } from '~/api/kickflowGoogleDriveApi'
import { KickflowGradeApi } from '~/api/kickflowGradeApi'
import { KickflowGsuiteApi } from '~/api/kickflowGsuiteApi'
import { KickflowLabelApi } from '~/api/kickflowLabelApi'
import { KickflowLineWorksApi } from '~/api/kickflowLineWorksApi'
import { KickflowMasterApi } from '~/api/kickflowMasterApi'
import { KickflowMfaApi } from '~/api/kickflowMfaApi'
import { KickflowMicrosoftTeamsApi } from '~/api/kickflowMicrosoftTeamsApi'
import { KickflowMonthlyActivityApi } from '~/api/kickflowMonthlyActivityApi'
import { KickflowNotificationApi } from '~/api/kickflowNotificationApi'
import { KickflowOrganizationChartApi } from '~/api/kickflowOrganizationChartApi'
import { KickflowPersonalAccessTokenApi } from '~/api/kickflowPersonalAccessTokenApi'
import { KickflowPipelineApi } from '~/api/kickflowPipelineApi'
import { KickflowProgressApi } from '~/api/kickflowProgressApi'
import {
  KickflowProxyApplicantApi,
  KickflowProxyApproverApi,
  KickflowProxySettingApi,
} from '~/api/kickflowProxyApi'
import { KickflowRegistrationApi } from '~/api/kickflowRegistrationApi'
import { KickflowRoleApi } from '~/api/kickflowRoleApi'
import { KickflowRouteApi } from '~/api/kickflowRouteApi'
import { KickflowSearchConditionApi } from '~/api/kickflowSearchConditionApi'
import { KickflowSecurityApi } from '~/api/kickflowSecurityApi'
import { KickflowServiceAccountApi } from '~/api/kickflowServiceAccountApi'
import { KickflowSlackApi } from '~/api/kickflowSlackApi'
import { KickflowStatsApi } from '~/api/kickflowStatsApi'
import { KickflowSubStatusApi } from '~/api/kickflowSubStatusApi'
import { KickflowTenantApi } from '~/api/kickflowTenantApi'
import { KickflowTicketApi } from '~/api/kickflowTicketApi'
import { KickflowTicketNumberKeyApi } from '~/api/kickflowTicketNumberKeyApi'
import { KickflowUserApi } from '~/api/kickflowUserApi'
import { KickflowViewSettingApi } from '~/api/kickflowViewSettingApi'
import { KickflowWebhookApi } from '~/api/kickflowWebhookApi'
import { KickflowWorkflowApi } from '~/api/kickflowWorkflowApi'

export default defineNuxtPlugin((nuxtApp) => {
  const context = nuxtApp as any
  return {
    provide: {
      kickflowAiApi: new KickflowAiApi(context.$httpClient),
      kickflowRegistrationApi: new KickflowRegistrationApi(context.$httpClient),
      kickflowTenantApi: new KickflowTenantApi(context.$httpClient),
      kickflowUserApi: new KickflowUserApi(context.$httpClient),
      kickflowAuthApi: new KickflowAuthApi(context.$httpClient),
      kickflowAutomationApi: new KickflowAutomationApi(context.$httpClient),
      kickflowOrganizationChartApi: new KickflowOrganizationChartApi(
        context.$httpClient
      ),
      kickflowRoleApi: new KickflowRoleApi(context.$httpClient),
      kickflowGradeApi: new KickflowGradeApi(context.$httpClient),
      kickflowWorkflowApi: new KickflowWorkflowApi(context.$httpClient),
      kickflowRouteApi: new KickflowRouteApi(context.$httpClient),
      kickflowFolderApi: new KickflowFolderApi(context.$httpClient),
      kickflowTicketNumberKeyApi: new KickflowTicketNumberKeyApi(
        context.$httpClient
      ),
      kickflowPipelineApi: new KickflowPipelineApi(context.$httpClient),
      kickflowCalculationVariableApi: new KickflowCalculationVariableApi(
        context.$httpClient
      ),
      kickflowTicketApi: new KickflowTicketApi(context.$httpClient),
      kickflowLabelApi: new KickflowLabelApi(context.$httpClient),
      kickflowSubStatusApi: new KickflowSubStatusApi(context.$httpClient),
      kickflowCommentApi: new KickflowCommentApi(context.$httpClient),
      kickflowWebhookApi: new KickflowWebhookApi(context.$httpClient),
      kickflowServiceAccountApi: new KickflowServiceAccountApi(
        context.$httpClient
      ),
      kickflowMasterApi: new KickflowMasterApi(context.$httpClient),
      kickflowAuditLogApi: new KickflowAuditLogApi(context.$httpClient),
      kickflowNotificationApi: new KickflowNotificationApi(context.$httpClient),
      kickflowBillingApi: new KickflowBillingApi(context.$httpClient),
      kickflowPersonalAccessTokenApi: new KickflowPersonalAccessTokenApi(
        context.$httpClient
      ),
      kickflowSlackApi: new KickflowSlackApi(context.$httpClient),
      kickflowBoxApi: new KickflowBoxApi(context.$httpClient),
      kickflowChatworkApi: new KickflowChatworkApi(context.$httpClient),
      kickflowMicrosoftTeamsApi: new KickflowMicrosoftTeamsApi(
        context.$httpClient
      ),
      kickflowGoogleChatApi: new KickflowGoogleChatApi(context.$httpClient),
      kickflowGoogleDriveApi: new KickflowGoogleDriveApi(context.$httpClient),
      kickflowClimberCloudApi: new KickflowClimberCloudApi(context.$httpClient),
      kickflowGsuiteApi: new KickflowGsuiteApi(context.$httpClient),
      kickflowCloudSignApi: new KickflowCloudSignApi(context.$httpClient),
      kickflowProxyApplicantApi: new KickflowProxyApplicantApi(
        context.$httpClient
      ),
      kickflowProxyApproverApi: new KickflowProxyApproverApi(
        context.$httpClient
      ),
      kickflowProxySettingApi: new KickflowProxySettingApi(context.$httpClient),
      kickflowSecurityApi: new KickflowSecurityApi(context.$httpClient),
      kickflowProgressApi: new KickflowProgressApi(context.$httpClient),
      kickflowFileApi: new KickflowFileApi(context.$httpClient),
      kickflowExternalApi: new KickflowExternalApi(context.$httpClient),
      kickflowStatsApi: new KickflowStatsApi(context.$httpClient),
      kickflowFcmTokenApi: new KickflowFcmTokenApi(context.$httpClient),
      kickflowLineWorksApi: new KickflowLineWorksApi(context.$httpClient),
      kickflowViewSettingApi: new KickflowViewSettingApi(context.$httpClient),
      kickflowAssistantApi: new KickflowAssistantApi(context.$httpClient),
      kickflowMfaApi: new KickflowMfaApi(context.$httpClient),
      kickflowMonthlyActivityApi: new KickflowMonthlyActivityApi(
        context.$httpClient
      ),
      KickflowSearchConditionApi: new KickflowSearchConditionApi(
        context.$httpClient
      ),
    },
  }
})
