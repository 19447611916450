import { KickflowApi } from '~/api/kickflowApi'
import type { GptProgress } from '~/types/ai'
import type { InputAssistBody } from '~/types/body/aiBody'

/**
 * AI API
 */
export class KickflowAiApi extends KickflowApi {
  async createInputAssist(params: InputAssistBody): Promise<GptProgress> {
    return await this.post('/ai/inputAssists', params)
  }

  async getInputAssist(id: string): Promise<GptProgress> {
    return await this.get(`/ai/inputAssists/${id}`)
  }
}
