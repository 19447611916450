import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Encoding } from '~/types/encoding'
import type { NotificationSetting } from '~/types/notificationSetting'
import type { Progress } from '~/types/progress'
import type { Role } from '~/types/role'
import type { MemberTeam, Team } from '~/types/team'
import type {
  CurrentUser,
  DetailedUser,
  NotificationEmailAddress,
  User,
  UserCount,
  UserInvitationValidityPeriodSetting,
  UserLocale,
  UserStatus,
  UserSubmittableResult,
} from '~/types/user'

export interface KickflowUserApiSearchUsersParams {
  status?: UserStatus[]
  email?: string
  fullName?: string
  employeeId?: string
  teamIds?: string[] | null
  targets?: {
    teamId: string | null
    descendants: boolean
    gradeSymbol: string | null
    gradeIds: string[]
  }[]
}

/**
 * ユーザーAPI
 */
export class KickflowUserApi extends KickflowApi {
  async getCurrentUser(): Promise<CurrentUser> {
    return await this.get('/user')
  }

  async getUser(id: string): Promise<DetailedUser> {
    return await this.get(`/users/${id}`)
  }

  async getUsers(paging: PagingQuery): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/users', queryParams)
  }

  async searchUsers(
    paging: PagingQuery,
    params: KickflowUserApiSearchUsersParams
  ): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    if (params.status) {
      queryParams.status = params.status
    }
    if (params.email) {
      queryParams.email = params.email
    }
    if (params.fullName) {
      queryParams.fullName = params.fullName
    }
    if (params.employeeId) {
      queryParams.employeeId = params.employeeId
    }
    if (params.teamIds) {
      queryParams.teamIds = params.teamIds
    }
    if (params.targets) {
      queryParams.targets = params.targets
    }
    return await this.getPagingList('/users/search', queryParams)
  }

  async searchUsersByNameOrEmail(
    paging: PagingQuery,
    name: string
  ): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    queryParams.name = name.trim()
    return await this.getPagingList('/users/searchByNameOrEmail', queryParams)
  }

  async getUnaffiliatedUsers(
    paging: PagingQuery,
    statusList?: UserStatus[]
  ): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    if (statusList) {
      queryParams.status = statusList
    }
    return await this.getPagingList('/users/unaffiliated', queryParams)
  }

  async getUserCount(): Promise<UserCount> {
    return await this.get('/users/count')
  }

  async inviteUser(params: {
    email: string
    code: string | null
    firstName: string | null
    lastName: string | null
    locale: string
    sendEmail: boolean
    employeeId: string | null
  }) {
    return await this.post('/users/invite', params)
  }

  async updateUserName(
    user: User,
    params: { firstName: string; lastName: string; code: string | null }
  ): Promise<DetailedUser> {
    return await this.put(`/users/${user.id}`, params)
  }

  async updateUserEmail(
    user: User,
    params: { email: string }
  ): Promise<DetailedUser> {
    return await this.put(`/users/${user.id}`, params)
  }

  async updateAvatar(user: User, signedId: string | null) {
    const params = {
      avatar: signedId,
    }
    return await this.patch(`/user/avatar`, params)
  }

  async updateUserLineWorksAccount(
    user: User,
    params: { lineWorksAccountId: string }
  ): Promise<DetailedUser> {
    return await this.post(`/users/${user.id}/lineWorksAccounts`, params)
  }

  async reactivateUser(user: User): Promise<DetailedUser> {
    return await this.post(`/users/${user.id}/reactivate`)
  }

  async suspendUser(user: User): Promise<DetailedUser> {
    return await this.post(`/users/${user.id}/suspend`)
  }

  async deactivateUser(user: User): Promise<DetailedUser> {
    return await this.delete(`/users/${user.id}`)
  }

  async reinviteUser(user: User): Promise<DetailedUser> {
    return await this.post(`/users/${user.id}/reinvite`)
  }

  async resendInvitation(user: User) {
    return await this.post(`/users/${user.id}/resendInvitation`)
  }

  async updateNotificationSetting(params: NotificationSetting) {
    return await this.put('/user/notificationSetting', params)
  }

  async createNotificationEmailAddress(email: string) {
    return await this.post('/notificationEmailAddresses', { email })
  }

  async deleteNotificationEmailAddress(email: NotificationEmailAddress) {
    return await this.delete(`/notificationEmailAddresses/${email.id}`)
  }

  async importCsv(blobSignedId: string): Promise<Progress> {
    return await this.post('/users/import', { file: blobSignedId })
  }

  async exportCsv(encoding: Encoding): Promise<Progress> {
    const params = {
      encoding,
    }
    return await this.get('/users/csv', params)
  }

  async resetPassword() {
    return await this.post('/user/resetPassword')
  }

  async updateLocale(locale: UserLocale) {
    const params = {
      locale,
    }
    return await this.patch('/user/locale', params)
  }

  async createGoogleChatSetting(tokenId: string) {
    const params = { tokenId }
    return await this.post('/user/googleChatSetting', params)
  }

  async getMemberTeams(
    paging: PagingQuery,
    userId: string
  ): Promise<PagingList<MemberTeam>> {
    const params = paging.toParams()
    return await this.getPagingList(`/users/${userId}/memberships`, params)
  }

  async getTeams(
    paging: PagingQuery,
    userId: string,
    organizationId: string | null,
    q: string | null
  ): Promise<PagingList<Team>> {
    const params = paging.toParams()
    if (organizationId) {
      params.organizationChartId = organizationId
    }
    params.q = q
    return await this.getPagingList(`/users/${userId}/teams`, params)
  }

  async getSubmittableTeams(
    paging: PagingQuery,
    userId: string,
    organizationId: string | null,
    q: string | null
  ): Promise<PagingList<Team>> {
    const params = paging.toParams()
    params.submittable = true // 申請できるチームだけ返す
    if (organizationId) {
      params.organizationChartId = organizationId
    }
    params.q = q
    return await this.getPagingList(`/users/${userId}/teams`, params)
  }

  async getSubmittableTeamsForWorkflow(
    paging: PagingQuery,
    userId: string,
    workflowId: string,
    q: string | null,
    priorityTeamId: string | null
  ): Promise<PagingList<Team>> {
    const queryParams = paging.toParams()
    if (workflowId) {
      queryParams.workflowId = workflowId
    }
    if (q) {
      queryParams.q = q
    }
    if (priorityTeamId) {
      queryParams.priorityTeamId = priorityTeamId
    }
    return await this.getPagingList(
      `/users/${userId}/submittableTeams`,
      queryParams
    )
  }

  async getUserRoles(
    paging: PagingQuery,
    userId: string
  ): Promise<PagingList<Role>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(`/users/${userId}/roles`, queryParams)
  }

  async checkSubmittable(
    workflowId: string | null | undefined
  ): Promise<UserSubmittableResult> {
    const queryParams: Record<string, string> = {}
    if (workflowId) {
      queryParams.workflowId = workflowId
    }
    return await this.get('/user/submittable', queryParams)
  }

  /**
   * ユーザ招待URLの有効期間変更
   */
  async getUserInvitationValidityPeriodSetting(): Promise<UserInvitationValidityPeriodSetting> {
    return await this.get('/userInvitationValidityPeriodSetting')
  }

  async updateUserInvitationValidityPeriodSetting(
    params: UserInvitationValidityPeriodSetting
  ) {
    return await this.put('/userInvitationValidityPeriodSetting', params)
  }
}
